<template>
  <a-table
    :columns="columns"
    :rowKey="record => record.Id"
    :dataSource="data"
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
  >
      <span slot="action" slot-scope="item">
        <a-button type="primary">
            <router-link :to="{ name: 'attributes-edit', params: { attributeId: item.Id } }">
              Редактировать
            </router-link>
          </a-button>
      </span>
  </a-table>
</template>
<script>

import { sortQuery } from '../../helpers';

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      page: 1,
      columns: [
        {
          title: 'Наименование Атрибута',
          dataIndex: 'Name',
          scopedSlots: { customRender: 'Name' },
          sorter: true,
        },
        {
          title: 'Действие',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      const field = sortQuery(sorter);

      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sort: field,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      this.$api.getAttributes({ page: this.page, ...params }, ({ data }) => {
        this.loading = false;
        this.data = data.data;
        this.pagination = {
          pageSize: data.meta.per_page,
          current: data.meta.current_page,
          total: data.meta.total,
        };
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
